import React, { useState, useEffect, useRef, useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { setStartDate, setEndDate, addDateComment } from "../../redux/actions";
import "./ActionPanel.css";
const ETAComponent = ({ itemId, setComments }) => {
  const dispatch = useDispatch();
  const [localStartDate, setLocalStartDate] = useState(null);
  const [localEndDate, setLocalEndDate] = useState(null);
  const isUpdating = useRef(false); // Prevents duplicate API calls

  // ✅ Fetch task details when itemId changes
  useEffect(() => {
    if (!itemId) return;

    const fetchTaskDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/items/${itemId}`
        );
        if (response.ok) {
          const data = await response.json();
          setLocalStartDate(data.start_date ? new Date(data.start_date) : null);
          setLocalEndDate(data.end_date ? new Date(data.end_date) : null);

          dispatch(
            setStartDate(data.start_date ? new Date(data.start_date) : null)
          );
          dispatch(setEndDate(data.end_date ? new Date(data.end_date) : null));
        } else {
          console.error("Failed to fetch task details");
        }
      } catch (error) {
        console.error("Error fetching task details:", error);
      }
    };

    fetchTaskDetails();
  }, [itemId, dispatch]);
  //testing the changes in frontend
  // ✅ Update date in backend and Redux, and update comments instantly
  const handleDateChange = useCallback(
    async (date, type) => {
      if (!date || isNaN(date.getTime()) || !itemId) {
        console.error(`❌ Invalid ${type} date or missing itemId`);
        return;
      }

      console.log(`🕒 Updating ${type.toUpperCase()} Date:`, date);

      if (isUpdating.current) return;
      isUpdating.current = true;

      const formattedDate = date.toISOString();
      const endpoint =
        type === "start"
          ? `${process.env.REACT_APP_API_URL}/items/${itemId}/saveStartDate`
          : `${process.env.REACT_APP_API_URL}/items/${itemId}/saveEndDate`;

      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            [type === "start" ? "startDate" : "endDate"]: formattedDate,
            user: localStorage.getItem("username"),
          }),
        });

        if (!response.ok) throw new Error("❌ Failed to update date");

        console.log(`✅ ${type.toUpperCase()} Date Updated:`, formattedDate);

        // ✅ Update Redux store instantly
        if (type === "start") {
          setLocalStartDate(date);
          dispatch(setStartDate(date));
        } else {
          setLocalEndDate(date);
          dispatch(setEndDate(date));
        }

        // ✅ Add Date Change Comment & Update UI Instantly
        const newComment = {
          text: `${
            type === "start" ? "Start" : "End"
          } Date selected: ${date.toLocaleDateString()}`,
          postedBy: "System",
          postedAt: new Date(),
        };
        dispatch(addDateComment(newComment));

        if (setComments) {
          setComments((prevComments) => [...prevComments, newComment]);
        }

        // ✅ Fetch latest comments and update UI immediately
        fetch(`${process.env.REACT_APP_API_URL}/items/${itemId}/comments`)
          .then((res) => res.json())
          .then((commentsData) => {
            setComments(commentsData);
            console.log("🔄 Comments Updated Instantly:", commentsData);
          });
      } catch (error) {
        console.error("❌ Error updating date:", error);
      } finally {
        isUpdating.current = false;
      }
    },
    [itemId, dispatch, setComments]
  );

  return (
    <div className="date-input-wrapper">
      <label>Start Date:</label>
      <DatePicker
        selected={localStartDate}
        onChange={(date) => handleDateChange(date, "start")}
        className="date-inputs"
      />

      <label>End Date:</label>
      <DatePicker
        selected={localEndDate}
        onChange={(date) => handleDateChange(date, "end")}
        className="date-inputs"
      />
    </div>
  );
};

export default React.memo(ETAComponent);
//ui i salos updating
// import React, { useState, useEffect, useRef, useCallback } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { useDispatch, useSelector } from "react-redux";
// import { setStartDate, setEndDate, addDateComment } from "../../redux/actions";

// const ETAComponent = ({ itemId }) => {
//   const [localStartDate, setLocalStartDate] = useState(null);
//   const [localEndDate, setLocalEndDate] = useState(null);
//   const dispatch = useDispatch();
//   const prevItemId = useRef(null);
//   const isUpdating = useRef(false);

//   useEffect(() => {
//     if (!itemId || prevItemId.current === itemId) return;
//     prevItemId.current = itemId;

//     console.log("🔄 Fetching task details for itemId:", itemId);

//     const fetchTaskDetails = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/items/${itemId}`
//         );
//         if (response.ok) {
//           const data = await response.json();
//           setLocalStartDate(data.start_date ? new Date(data.start_date) : null);
//           setLocalEndDate(data.end_date ? new Date(data.end_date) : null);
//           dispatch(
//             setStartDate(data.start_date ? new Date(data.start_date) : null)
//           );
//           dispatch(setEndDate(data.end_date ? new Date(data.end_date) : null));
//         } else {
//           console.error("❌ Failed to fetch task details");
//         }
//       } catch (error) {
//         console.error("❌ Error fetching task details:", error);
//       }
//     };

//     fetchTaskDetails();
//   }, [itemId, dispatch]);

//   const updateDate = useCallback(
//     async (date, type) => {
//       if (!date || isNaN(date.getTime()) || !itemId) {
//         console.error("❌ Invalid date or missing itemId");
//         return;
//       }

//       console.log(
//         `🕒 Updating ${type.toUpperCase()} Date:`,
//         date.toISOString()
//       );

//       if (isUpdating.current) {
//         console.warn("🚫 Already updating, skipping duplicate request.");
//         return;
//       }
//       isUpdating.current = true;

//       const endpoint = `${process.env.REACT_APP_API_URL}/items/${itemId}/save${
//         type === "start" ? "StartDate" : "EndDate"
//       }`;

//       try {
//         const response = await fetch(endpoint, {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({
//             [`${type}Date`]: date,
//             user: localStorage.getItem("username"),
//           }),
//         });

//         if (!response.ok) {
//           console.error("❌ Failed to update date");
//           return;
//         }

//         console.log(`✅ ${type.toUpperCase()} Date Updated:`, date);

//         if (type === "start") {
//           setLocalStartDate(date);
//           dispatch(setStartDate(date));
//         } else {
//           setLocalEndDate(date);
//           dispatch(setEndDate(date));
//         }

//         dispatch(
//           addDateComment({
//             text: `${type.toUpperCase()} date set: ${date.toLocaleDateString()}`,
//             postedBy: "System",
//             postedAt: new Date(),
//           })
//         );
//       } catch (error) {
//         console.error("❌ Error updating date:", error);
//       } finally {
//         isUpdating.current = false;
//       }
//     },
//     [itemId, dispatch]
//   );

//   return (
//     <>
//       <div>
//         <label>Start Date:</label>
//         <DatePicker
//           selected={localStartDate}
//           onChange={(date) => updateDate(date, "start")}
//           className="priority-boxes"
//         />
//       </div>
//       <div>
//         <label>End Date:</label>
//         <DatePicker
//           selected={localEndDate}
//           onChange={(date) => updateDate(date, "end")}
//           className="priority-boxes"
//         />
//       </div>
//     </>
//   );
// };

// export default React.memo(ETAComponent);

// import React, { useState, useEffect, useCallback, useRef } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// const ETAComponent = ({
//   itemId,
//   startDate,
//   endDate,
//   handleSaveDates,
//   comments = [],
//   setComments,
// }) => {
//   const [localStartDate, setLocalStartDate] = useState(startDate || null);
//   const [localEndDate, setLocalEndDate] = useState(endDate || null);
//   const isUpdating = useRef(false); // ✅ Prevents repeated updates

//   // ✅ Only update local state when `startDate` or `endDate` changes
//   useEffect(() => {
//     setLocalStartDate(startDate);
//     setLocalEndDate(endDate);
//   }, [startDate, endDate]);

//   // ✅ Prevent unnecessary API calls and state updates
//   const handleDateChange = useCallback(
//     async (date, type) => {
//       if (!date || isNaN(date.getTime()) || !itemId) {
//         console.error("❌ Invalid date or missing itemId");
//         return;
//       }

//       console.log(`🕒 Selected Date: ${date.toISOString()} | Type: ${type}`);

//       // Prevent API call if the date hasn't changed
//       if (
//         (type === "start" &&
//           localStartDate?.toISOString() === date.toISOString()) ||
//         (type === "end" && localEndDate?.toISOString() === date.toISOString())
//       ) {
//         console.log("🔄 No changes detected, skipping update.");
//         return;
//       }

//       if (isUpdating.current) return; // Prevents duplicate API calls
//       isUpdating.current = true;

//       const endpoint =
//         type === "start"
//           ? `/api/items/${itemId}/saveStartDate`
//           : `/api/items/${itemId}/saveEndDate`;
//       const formattedDate = date.toISOString();

//       console.log("📡 Sending API Request:", endpoint, formattedDate);

//       try {
//         const response = await fetch(endpoint, {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({ [`${type}Date`]: formattedDate, itemId }),
//         });

//         if (!response.ok) {
//           throw new Error("❌ Failed to update date");
//         }

//         console.log(`✅ ${type.toUpperCase()} Date Updated:`, formattedDate);

//         // ✅ Update only if necessary to prevent infinite loops
//         if (type === "start") {
//           setLocalStartDate(date);
//         } else {
//           setLocalEndDate(date);
//         }

//         if (typeof handleSaveDates === "function") {
//           handleSaveDates({
//             startDate: type === "start" ? date : localStartDate,
//             endDate: type === "end" ? date : localEndDate,
//           });
//         }

//         // ✅ Fetch comments only after date update
//         const commentsResponse = await fetch(`/api/items/${itemId}/comments`);
//         const commentsData = await commentsResponse.json();
//         setComments(Array.isArray(commentsData) ? commentsData : []);
//         console.log("🔄 Comments Updated Instantly:", commentsData);
//       } catch (error) {
//         console.error("❌ Error updating date:", error);
//       } finally {
//         isUpdating.current = false;
//       }
//     },
//     [itemId, localStartDate, localEndDate, handleSaveDates, setComments]
//   );

//   return (
//     <div>
//       <label>Start Date:</label>
//       <DatePicker
//         selected={localStartDate}
//         onChange={(date) => handleDateChange(date, "start")}
//       />

//       <label>End Date:</label>
//       <DatePicker
//         selected={localEndDate}
//         onChange={(date) => handleDateChange(date, "end")}
//       />
//     </div>
//   );
// };

// export default React.memo(ETAComponent);

//getting both but rendering
// import React, { useState, useEffect } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// const ETAComponent = ({
//   itemId,
//   startDate,
//   endDate,
//   handleSaveDates,
//   comments = [],
//   setComments,
// }) => {
//   const [localStartDate, setLocalStartDate] = useState(startDate || null);
//   const [localEndDate, setLocalEndDate] = useState(endDate || null);

//   // ✅ Ensure itemId is valid before fetching data
//   useEffect(() => {
//     if (!itemId) {
//       console.warn("⚠️ Skipping fetch: itemId is missing.");
//       return;
//     }
//     console.log("🔄 Task changed! Fetching new dates for itemId:", itemId);

//     const fetchTaskDetails = async () => {
//       try {
//         const response = await fetch(`/api/items/${itemId}`);
//         if (response.ok) {
//           const data = await response.json();
//           console.log("✅ Fetched Task Data:", data);

//           setLocalStartDate(data.start_date ? new Date(data.start_date) : null);
//           setLocalEndDate(data.end_date ? new Date(data.end_date) : null);

//           if (typeof handleSaveDates === "function") {
//             handleSaveDates({
//               startDate: data.start_date ? new Date(data.start_date) : null,
//               endDate: data.end_date ? new Date(data.end_date) : null,
//             });
//           }
//         } else {
//           console.error("❌ Failed to fetch task details:", response.status);
//         }
//       } catch (error) {
//         console.error("❌ Error fetching task details:", error);
//       }
//     };

//     fetchTaskDetails();
//   }, [itemId]);

//   // ✅ Fetch comments only when itemId changes
//   useEffect(() => {
//     if (!setComments || !itemId) return;
//     console.log("📡 Fetching comments for itemId:", itemId);

//     const fetchComments = async () => {
//       try {
//         const response = await fetch(`/api/items/${itemId}/comments`);
//         if (response.ok) {
//           const data = await response.json();
//           setComments(Array.isArray(data) ? data : []);
//           console.log("✅ Comments Updated:", data);
//         }
//       } catch (error) {
//         console.error("❌ Error fetching comments:", error);
//       }
//     };

//     fetchComments();
//   }, [itemId]);

//   // ✅ Handle date changes and prevent unnecessary re-renders
//   const handleDateChange = async (date, type) => {
//     if (!date || isNaN(date.getTime())) {
//       console.error("❌ Invalid Date Selected:", date);
//       return;
//     }
//     if (!itemId) {
//       console.error("❌ Missing itemId. Cannot update date.");
//       return;
//     }

//     console.log(`🕒 ${type.toUpperCase()} Date Picked:`, date);

//     const formattedDate = date.toISOString();
//     const endpoint =
//       type === "start"
//         ? `/api/items/${itemId}/saveStartDate`
//         : `/api/items/${itemId}/saveEndDate`;

//     console.log("📡 Sending API Request:", endpoint, formattedDate);

//     try {
//       const response = await fetch(endpoint, {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ [`${type}Date`]: formattedDate, itemId }),
//       });

//       if (response.ok) {
//         console.log(`✅ ${type.toUpperCase()} Date Updated:`, formattedDate);

//         // ✅ Only update state if date actually changed
//         if (
//           type === "start" &&
//           localStartDate?.toISOString() !== formattedDate
//         ) {
//           setLocalStartDate(date);
//         }
//         if (type === "end" && localEndDate?.toISOString() !== formattedDate) {
//           setLocalEndDate(date);
//         }

//         if (typeof handleSaveDates === "function") {
//           handleSaveDates({
//             startDate: type === "start" ? date : localStartDate,
//             endDate: type === "end" ? date : localEndDate,
//           });
//         }

//         // ✅ Fetch comments after date update
//         fetch(`/api/items/${itemId}/comments`)
//           .then((res) => res.json())
//           .then((data) => {
//             setComments(Array.isArray(data) ? data : []);
//             console.log("🔄 Comments Updated Instantly:", data);
//           });
//       } else {
//         console.error("❌ Failed to update date:", response.status);
//       }
//     } catch (error) {
//       console.error("❌ Error updating date:", error);
//     }
//   };

//   return (
//     <div>
//       <label>Start Date:</label>
//       <DatePicker
//         selected={localStartDate}
//         onChange={(date) => handleDateChange(date, "start")}
//       />

//       <label>End Date:</label>
//       <DatePicker
//         selected={localEndDate}
//         onChange={(date) => handleDateChange(date, "end")}
//       />
//     </div>
//   );
// };

// export default ETAComponent;

//updates the ui
// import React, { useState, useEffect, useCallback, useRef } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// const ETAComponent = ({
//   itemId,
//   startDate,
//   endDate,
//   handleSaveDates,
//   comments = [],
//   setComments,
// }) => {
//   const [localStartDate, setLocalStartDate] = useState(startDate || null);
//   const [localEndDate, setLocalEndDate] = useState(endDate || null);
//   const prevItemId = useRef(null); // Track last task to prevent extra fetches

//   useEffect(() => {
//     if (prevItemId.current === itemId) return; // Prevent duplicate fetches
//     prevItemId.current = itemId;

//     console.log("🔄 Task changed! Fetching new dates...");

//     const fetchTaskDetails = async () => {
//       try {
//         const response = await fetch(`/api/items/${itemId}`);
//         if (response.ok) {
//           const data = await response.json();
//           console.log("✅ Fetched Task Data:", data);

//           // Prevent unnecessary state updates
//           setLocalStartDate((prev) =>
//             data.start_date && prev?.toISOString() !== data.start_date
//               ? new Date(data.start_date)
//               : prev
//           );

//           setLocalEndDate((prev) =>
//             data.end_date && prev?.toISOString() !== data.end_date
//               ? new Date(data.end_date)
//               : prev
//           );

//           if (typeof handleSaveDates === "function") {
//             handleSaveDates({
//               startDate: data.start_date ? new Date(data.start_date) : null,
//               endDate: data.end_date ? new Date(data.end_date) : null,
//             });
//           }
//         }
//       } catch (error) {
//         console.error("❌ Error fetching task details:", error);
//       }
//     };

//     if (itemId) {
//       fetchTaskDetails();
//     }
//   }, [itemId, handleSaveDates]);

//   const handleDateChange = useCallback(
//     async (date, type) => {
//       if (!date || isNaN(date.getTime())) return;

//       console.log(`🕒 ${type.toUpperCase()} Date Picked:`, date);

//       const formattedDate = date.toISOString();

//       // Prevent API call & state update if date hasn't changed
//       if (
//         (type === "start" && localStartDate?.toISOString() === formattedDate) ||
//         (type === "end" && localEndDate?.toISOString() === formattedDate)
//       ) {
//         console.log("🔄 No changes detected, skipping update.");
//         return;
//       }

//       const endpoint =
//         type === "start"
//           ? `/api/items/${itemId}/saveStartDate`
//           : `/api/items/${itemId}/saveEndDate`;

//       try {
//         const response = await fetch(endpoint, {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({ [`${type}Date`]: formattedDate, itemId }),
//         });

//         if (response.ok) {
//           console.log(`✅ ${type.toUpperCase()} Date Updated:`, formattedDate);

//           if (type === "start") {
//             setLocalStartDate(date);
//           } else {
//             setLocalEndDate(date);
//           }

//           if (typeof handleSaveDates === "function") {
//             handleSaveDates({
//               startDate: type === "start" ? date : localStartDate,
//               endDate: type === "end" ? date : localEndDate,
//             });
//           }
//         }
//       } catch (error) {
//         console.error("❌ Error updating date:", error);
//       }
//     },
//     [itemId, localStartDate, localEndDate, handleSaveDates]
//   );

//   return (
//     <div>
//       <label>Start Date:</label>
//       <DatePicker
//         selected={localStartDate}
//         onChange={(date) => handleDateChange(date, "start")}
//       />

//       <label>End Date:</label>
//       <DatePicker
//         selected={localEndDate}
//         onChange={(date) => handleDateChange(date, "end")}
//       />
//     </div>
//   );
// };

// export default ETAComponent;

//it will work only dates not ui

// import React, { useState, useEffect } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// const ETAComponent = ({
//   itemId,
//   startDate,
//   endDate,
//   handleSaveDates,
//   comments = [], // Ensure comments has a default empty array
//   setComments,
// }) => {
//   const [localStartDate, setLocalStartDate] = useState(startDate || null);
//   const [localEndDate, setLocalEndDate] = useState(endDate || null);

//   useEffect(() => {
//     setLocalStartDate(startDate);
//     setLocalEndDate(endDate);
//   }, [startDate, endDate]);

//   // Function to fetch comments after date update
//   const fetchComments = async () => {
//     if (!setComments) {
//       console.error("⚠️ `setComments` is not provided, skipping fetch.");
//       return;
//     }
//     try {
//       const response = await fetch(`/api/items/${itemId}/comments`);
//       if (response.ok) {
//         const data = await response.json();

//         // Ensure comments is an array before setting state
//         setComments(Array.isArray(data) ? data : []);

//         console.log("🔄 Comments Updated Instantly:", data);
//       } else {
//         console.error("❌ Failed to fetch comments:", response.status);
//       }
//     } catch (error) {
//       console.error("❌ Error fetching comments:", error);
//     }
//   };

//   const handleDateChange = async (date, type) => {
//     if (!date || isNaN(date.getTime())) return;

//     console.log(`🕒 ${type.toUpperCase()} Date Picked:`, date);

//     const formattedDate = date.toISOString();
//     const endpoint =
//       type === "start"
//         ? `/api/items/${itemId}/saveStartDate`
//         : `/api/items/${itemId}/saveEndDate`;

//     console.log("📡 Sending API Request:", endpoint, formattedDate);

//     try {
//       const response = await fetch(endpoint, {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ [`${type}Date`]: formattedDate, itemId }),
//       });

//       if (response.ok) {
//         console.log(`✅ ${type.toUpperCase()} Date Updated:`, formattedDate);

//         if (type === "start") {
//           setLocalStartDate(date);
//         } else {
//           setLocalEndDate(date);
//         }

//         if (typeof handleSaveDates === "function") {
//           handleSaveDates({
//             startDate: localStartDate,
//             endDate: localEndDate,
//           });
//         }

//         // Fetch updated comments after date update
//         await fetchComments();
//       } else {
//         console.error("❌ Failed to update date:", response.status);
//       }
//     } catch (error) {
//       console.error("❌ Error updating date:", error);
//     }
//   };

//   return (
//     <div>
//       <label>Start Date:</label>
//       <DatePicker
//         selected={localStartDate}
//         onChange={(date) => handleDateChange(date, "start")}
//       />

//       <label>End Date:</label>
//       <DatePicker
//         selected={localEndDate}
//         onChange={(date) => handleDateChange(date, "end")}
//       />
//     </div>
//   );
// };

// export default ETAComponent;
