import React, { useState, useEffect } from "react";
import CommentForm from "../../Comments/CommentForm";
import { Link } from "react-router-dom";
import CommentList from "../../Comments/CommentList";
import "../styles/ItemComponent.css";
import { useSelector, useDispatch } from "react-redux";
import { addCommentSuccess } from "../../../redux/actions";
import { copyToClipboard } from "../../../utils/clipboardUtils";
import "bootstrap-icons/font/bootstrap-icons.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";

function ItemComponent({ item, onItemUpdated }) {
  const dispatch = useDispatch();
  const [comments, setComments] = useState([]);
  const [refreshItem, setRefreshItem] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [taskDetails, setTaskDetails] = useState(item?.description || ""); // ✅ Ensure taskDetails never starts as undefined
  const [copied, setCopied] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);

  useEffect(() => {
    setTaskDetails(item?.description || ""); // ✅ Ensure taskDetails is always valid
  }, [item]);

  useEffect(() => {
    const fetchComments = async () => {
      if (item && item.itemId) {
        try {
          // ✅ Fixed Incorrect String Interpolation
          const response = await fetch(`/api/items/${item.itemId}/comments`);
          if (!response.ok) {
            throw new Error(`Server responded with status: ${response.status}`);
          }
          const data = await response.json();
          console.log("Received comments data:", data);

          setComments(Array.isArray(data) ? data : []); // ✅ Ensuring `data` is an array
        } catch (error) {
          console.error("Error fetching comments:", error);
        }
      }
    };

    fetchComments();
  }, [item?.itemId, item, refreshItem]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  // Save the edited task details
  const handleCancelClick = () => {
    setTaskDetails(item?.description || "");
    setEditMode(false);
  };

  const handleSaveClick = async () => {
    if (!item?.itemId) return;
    if (taskDetails.trim() === (item.description || "").trim()) {
      setEditMode(false);
      return;
    }
    try {
      // ✅ Fixed Incorrect API URL String
      const response = await fetch(`/api/items/${item.itemId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ description: taskDetails }),
      });

      if (!response.ok) {
        throw new Error("Failed to update task details");
      }

      const editComment = `Task description updated: "${taskDetails}"`;
      await addEditComment(item.itemId, editComment);

      setEditMode(false);
      onItemUpdated({
        ...item,
        description: taskDetails,
      });
    } catch (error) {
      console.error("Error saving task details:", error);
    }
  };

  const addEditComment = async (itemId, commentText) => {
    if (!itemId) return;
    const username = localStorage.getItem("username") || "Anonymous";
    try {
      // ✅ Fixed Incorrect API URL String
      const commentResponse = await fetch(`/api/items/${itemId}/comments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: commentText, postedBy: username }),
      });

      if (!commentResponse.ok) {
        throw new Error("Failed to add edit comment");
      }

      const newComment = await commentResponse.json();
      handleCommentSubmitted(newComment);
    } catch (error) {
      console.error("Error adding edit comment:", error);
    }
  };

  const handleCommentSubmitted = (newComment) => {
    dispatch(addCommentSuccess(item.itemId, newComment));
    setComments((prevComments) => [...prevComments, newComment]); // ✅ Ensures comments are updated properly
    triggerRefreshComments();
  };

  const triggerRefreshComments = () => {
    setRefreshItem((prev) => !prev);
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  if (!item) {
    return <div>Please select a task to view its details.</div>;
  }

  const shareTaskIdtoClipboard = () => {
    window.open(`/task/${item.itemId}`, "_blank");
  };

  const copyTaskIdToClipboard = () => {
    copyToClipboard(`${window.location.origin}/task/${item.itemId}`)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => console.error("Failed to copy:", err));
  };

  return (
    <div className="task-id">
      {item.itemId && (
        <>
          <Link to={`/task/${item.itemId}`} target="_blank">
            TaskID: {item.itemId}
          </Link>

          <button className="share-button" onClick={shareTaskIdtoClipboard}>
            <FontAwesomeIcon icon={faShare} />
          </button>
          <button
            onClick={copyTaskIdToClipboard}
            className="copy-buttons"
            title="Copy URL to Clipboard"
          >
            <i
              className={`bi ${copied ? "bi-clipboard-check" : "bi-clipboard"}`}
            ></i>
          </button>
        </>
      )}
      <h3 className="item-title">{item.name}</h3>

      {editMode ? (
        <div className="edit-areas">
          <textarea
            className="edit-mode-textareas"
            value={taskDetails}
            onChange={(e) => setTaskDetails(e.target.value)}
          />
          <button className="button-linkstyles" onClick={handleSaveClick}>
            Save
          </button>
          <button className="button-linkstyle" onClick={handleCancelClick}>
            Cancel
          </button>
        </div>
      ) : (
        <div>
          <div className="item-description">
            {showFullDescription
              ? item.description
              : (item.description || "").slice(0, 50) + "..."}
          </div>
          {item.description?.length > 150 && (
            <span className="read-more-link" onClick={toggleDescription}>
              {showFullDescription ? "Read Less" : "Read More"}
            </span>
          )}
        </div>
      )}

      {!editMode && (
        <button className="button-linkstyle" onClick={handleEditClick}>
          Edit
        </button>
      )}

      <CommentList comments={comments} />
      <CommentForm
        itemId={item.itemId}
        onCommentSubmitted={handleCommentSubmitted}
      />
    </div>
  );
}

export default ItemComponent;

// //item component
// import React, { useState, useEffect } from "react";
// import CommentForm from "../../Comments/CommentForm";
// import { Link } from "react-router-dom";
// import CommentList from "../../Comments/CommentList";
// import "../styles/ItemComponent.css";
// import { useSelector, useDispatch } from "react-redux";
// import { addCommentSuccess } from "../../../redux/actions";
// import { copyToClipboard } from "../../../utils/clipboardUtils";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faShare } from "@fortawesome/free-solid-svg-icons";

// function ItemComponent({ item, onItemUpdated }) {
//   const dispatch = useDispatch();
//   const [comments, setComments] = useState([]);
//   const [refreshItem, setRefreshItem] = useState(false);
//   const [editMode, setEditMode] = useState(false);
//   const [taskDetails, setTaskDetails] = useState(item.description);
//   const [copied, setCopied] = useState(false);
//   const [showFullDescription, setShowFullDescription] = useState(false);

//   useEffect(() => {
//     setTaskDetails(item.description);
//   }, [item]);

//   useEffect(() => {
//     const fetchComments = async () => {
//       if (item && item.itemId) {
//         try {
//           const response = await fetch(`/api/items/${item.itemId}/comments`);
//           if (!response.ok) {
//             throw new Error(`Server responded with status: ${response.status}`);
//           }
//           const data = await response.json();
//           console.log("Received comments data:", data);
//           setComments(Array.isArray(data) ? data : []); // Set the comments in state
//         } catch (error) {
//           console.error("Error fetching comments:", error);
//         }
//       }
//     };

//     fetchComments();
//   }, [item.itemId, item, refreshItem]);
//   const commentsList = Array.isArray(comments) ? comments.slice() : [];
//   const handleEditClick = () => {
//     setEditMode(true);
//   };

//   // Save the edited task details
//   const handleCancelClick = () => {
//     setTaskDetails(item.description);
//     setEditMode(false);
//   };

//   const handleSaveClick = async () => {
//     if (taskDetails.trim() === item.description.trim()) {
//       // No changes made, so no need to save or add comment
//       setEditMode(false);
//       return;
//     }
//     try {
//       const response = await fetch(`/api/items/${item.itemId}`, {
//         method: "PUT",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ description: taskDetails }),
//       });
//       if (!response.ok) {
//         throw new Error("Failed to update task details");
//       }
//       const editComment = `Task description updated: "${taskDetails}"`;
//       await addEditComment(item.itemId, editComment);
//       setEditMode(false);
//       onItemUpdated({
//         ...item,
//         description: taskDetails,
//       });
//     } catch (error) {
//       console.error("Error saving task details:", error);
//     }
//   };

//   const addEditComment = async (itemId, commentText) => {
//     const username = localStorage.getItem("username") || "Anonymous";
//     try {
//       const commentResponse = await fetch(`/api/items/${itemId}/comments`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           text: commentText,
//           postedBy: username, // or use a user identifier if available
//         }),
//       });

//       if (!commentResponse.ok) {
//         throw new Error("Failed to add edit comment");
//       }

//       const newComment = await commentResponse.json();
//       handleCommentSubmitted(newComment);
//     } catch (error) {
//       console.error("Error adding edit comment:", error);
//     }
//   };

//   const handleCommentSubmitted = (newComment) => {
//     dispatch(addCommentSuccess(item.itemId, newComment));
//     setComments((prevComments) => [...prevComments, newComment]); // Use functional state update
//     triggerRefreshComments(); // Add the new comment to the local state
//   };

//   const triggerRefreshComments = () => {
//     setRefreshItem((prev) => !prev);
//   };

//   const createMarkup = (htmlContent) => {
//     return { __html: htmlContent };
//   };
//   const toggleDescription = () => {
//     setShowFullDescription(!showFullDescription);
//   };
//   if (!item) {
//     return <div>Please select a task to view its details.</div>;
//   }

//   const shareTaskIdtoClipboard = () => {
//     window.open(`/task/${item.itemId}`, "_blank");
//   };

//   const copyTaskIdToClipboard = () => {
//     copyToClipboard(`${window.location.origin}/task/${item.itemId}`)
//       .then(() => {
//         setCopied(true);
//         setTimeout(() => setCopied(false), 2000); // Reset icon after 2 seconds
//       })
//       .catch((err) => console.error("Failed to copy:", err));
//   };

//   return (
//     <div className="task-id">
//       {item.itemId && (
//         <>
//           <Link to={`/task/${item.itemId}`} target="_blank">
//             TaskID: {item.itemId}
//           </Link>

//           <button className="share-button" onClick={shareTaskIdtoClipboard}>
//             {" "}
//             <FontAwesomeIcon icon={faShare} />
//           </button>
//           <button
//             onClick={copyTaskIdToClipboard}
//             className=" copy-buttons"
//             title="Copy URL to Clipboard"
//           >
//             <i
//               className={`bi ${copied ? "bi-clipboard-check" : "bi-clipboard"}`}
//             ></i>
//           </button>
//         </>
//       )}
//       <h3 className="item-title">{item.name} </h3>

//       {editMode ? (
//         // If in edit mode, show a textarea for editing the description
//         <div className="edit-areas">
//           <textarea
//             className="edit-mode-textareas"
//             value={taskDetails}
//             onChange={(e) => setTaskDetails(e.target.value)}
//           />
//           <button className="button-linkstyles" onClick={handleSaveClick}>
//             save
//           </button>
//           <button className="button-linkstyle" onClick={handleCancelClick}>
//             cancel
//           </button>
//         </div>
//       ) : (
//         <div>
//           <div
//             className="item-description"
//             dangerouslySetInnerHTML={{
//               __html: showFullDescription
//                 ? item.description
//                 : item.description.slice(0, 50) + "...",
//             }}
//           />
//           {item.description.length > 150 && (
//             <span
//               className="read-more-link"
//               onClick={toggleDescription}
//               style={{
//                 color: "blue",
//                 cursor: "pointer",
//                 textDecoration: "underline",
//                 marginLeft: "5px",
//                 fontSize: "15px",
//               }}
//             >
//               {showFullDescription ? "Read Less" : "Read More"}
//             </span>

//             // <button className="read-more" onClick={toggleDescription}>
//             //   {showFullDescription ? "Read Less" : "Read More"}
//             // </button>
//           )}
//         </div>
//       )}

//       {!editMode && (
//         <button className="button-linkstyle" onClick={handleEditClick}>
//           edit
//         </button>
//       )}

//       <CommentList comments={commentsList} />
//       <CommentForm
//         itemId={item.itemId}
//         onCommentSubmitted={handleCommentSubmitted}
//         triggerRefreshComments={triggerRefreshComments}
//       />
//     </div>
//   );
// }
// //export defaults
// export default ItemComponent;
